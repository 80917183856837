import React from "react";

export default function Input({label, placeholder, name, type, value, defaultValue, onChange, required, light, disabled, marginTop}) {
	return (
		<div style={{marginTop: marginTop || 0}}>
			{label && <label htmlFor={name} className="block text-[13px] font-medium text-black">{label}</label>}
			<div className="mt-1">
				<input
					id={name}
					name={name}
					type={type || "text"}
					autoComplete={name}
					required={required}
					placeholder={placeholder}
					onChange={onChange}
					disabled={disabled}
					className="appearance-none block w-full px-3 py-2 border text-black border-gray-600 border-opacity-30 rounded-md shadow-none placeholder-gray-500 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm bg-white disabled:bg-[#eee]"
					value={value}
					defaultValue={defaultValue}
				/>
			</div>
		</div>
	)
}