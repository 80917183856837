import React from "react";
import { classNames } from "../../helpers/common";

export default function SectionTitle({title, subtitle, centered = true, color}) {
	return (
		<div 
			className={classNames(
				centered ? 'text-center' : '',
				'py-[5rem]'
			)}
		>
			<h2 className="text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl font-hn-ex">
				{title}
			</h2>
			<p className="mt-6 mx-auto max-w-md font-normal text-base text-neutral-400 sm:text-lg md:mt-6 md:text-lg md:max-w-xl">
				{subtitle}
			</p>
		</div>
	);
}
