import axios from "axios";
import { SERVER_URL } from "../constants/constants";

let api = axios.create({
	baseURL: SERVER_URL + "/",
	timeout: 18000,
});

export const setClientToken = (token) => {
	if (token) {
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		console.log("🟢 API_AUTH :", token);
	} else {
		delete api.defaults.headers.common["Authorization"];
	}
};

const responseSuccessHandler = (response) => {
	return response;
};

const responseErrorHandler = (error) => {
	if (error.response.status === 401) {
		// logout user on expired or invalid token
		const windowGlobal = typeof window !== "undefined" && window;
		if (windowGlobal) {
			localStorage.clear();
			window.location.href = "/";
		}
	}
	return Promise.reject(error);
};

api.interceptors.response.use(
	(response) => responseSuccessHandler(response),
	(error) => responseErrorHandler(error)
);

export default api;
