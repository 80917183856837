import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../../constants/constants";
import { useAuth } from "../../context/AuthContext";
import Input from "../ui/Input";
import Button from "../ui/Button";
import Notice from "../shared/Notice";

export default function Authentication({ insideModal, mode = "login" }) {
	const [loginMode, setLoginMode] = useState(mode == "login");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [payload, setPayload] = useState({});

	const { signIn, userData, setUserData, authModal } = useAuth();

	const ref_input2 = useRef();
	const ref_input3 = useRef();
	const ref_input4 = useRef();

	const authenticate = (event) => {
		event.preventDefault();
		setLoading(true);
		// const body = {
		// 	username: loginMode ? null : payload.email,
		// 	identifier: loginMode ?
		// }
		const formData = new FormData(event.currentTarget);
		formData.append("username", loginMode ? null : formData.get("email"));

		axios
			.post(
				`${SERVER_URL}/auth/${loginMode ? "local" : "local/register"}`,
				formData
			)
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					signIn(res.data);
				}
			})
			.catch((e) => {
				setLoading(false);
				if (e.response.status === 400) {
					setError(
						e.response?.data?.message[0]?.messages[0]?.message ||
							"Invalid email or password"
					);
				} else {
					setError("Something went wrong");
				}
			});
	};

	useEffect(() => {
		setError(false);
		setPayload({});
	}, [loginMode]);

	const switchMode = (e) => {
		e.preventDefault();
		setLoginMode(!loginMode);
	};

	return (
		<div>
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<div className=" py-8 px-4 sm:rounded-xl sm:px-10">
					<div className="text-2xl text-black font-medium text-center mb-8 font-hn-ex">
						{loginMode ? "Log In" : "Create an Account"}
					</div>

					<form
						className="space-y-4"
						action="#"
						method="POST"
						onSubmit={authenticate}
					>
						{!loginMode && (
							<>
								<Input
									type="text"
									name="firstName"
									label="First Name"
									required
									value={payload.firstName}
								/>
								<Input
									type="text"
									name="lastName"
									label="Last Name"
									required
									value={payload.lastName}
								/>
							</>
						)}
						<Input
							type="email"
							name={[loginMode ? "identifier" : "email"]}
							label="Email Address"
							required
							value={loginMode ? payload.identifier : payload.email}
						/>

						<Input
							label="Password"
							type="password"
							name="password"
							required
							//value={payload.password}
							onChange={(value) => setPayload({ ...payload, password: value })}
						/>

						{loginMode && (
							<div className="flex items-center justify-between">
								<div className="flex items-center">
									<input
										id="remember-me"
										name="remember-me"
										type="checkbox"
										className="h-4 w-4 text-primary focus:ring-primary bg-white border-gray-100 rounded"
										checked
									/>
									<label
										htmlFor="remember-me"
										className="ml-2 block text-sm text-gray-500"
									>
										Remember me
									</label>
								</div>

								<div className="text-sm">
									<a
										href="#"
										className="font-medium text-sm text-gray-500 hover:text-primary"
									>
										Forgot your password?
									</a>
								</div>
							</div>
						)}

						<div>
							<Button
								label={loginMode ? "Log In" : "Create Account"}
								size="large"
								type="submit"
								fullWidth
							/>
							{error && (
								<Notice type="error" className="mt-4" message={error} />
							)}

							{/* <button
							type="submit"
							className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
							>
							Sign in
							</button> */}

							<p className="text-sm text-black text-center mt-4">
								{loginMode
									? "Don't have an account? "
									: "Already have an account? "}
								<a
									href="#"
									onClick={switchMode}
									className="text-black underline hover:text-primary"
								>
									{loginMode ? "Sign up" : "Log in"}
								</a>
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
