/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css'
import "./src/styles/slick.css";
import "./src/styles/slick-theme.css";

import React from "react"
import { AuthProvider } from "./src/context/AuthContext"
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_live_HqNidXJPObpVpYpeyXWiORgL");

// TEST: pk_test_5uqrc6Wyy3NrK2OzMqELC9y2
// REAL: pk_live_HqNidXJPObpVpYpeyXWiORgL

export const wrapRootElement = ({ element }) => (
  <Elements stripe={stripePromise}>
    <AuthProvider>{element}</AuthProvider>
  </Elements>
)