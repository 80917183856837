import React, { createContext, useEffect } from "react";
import AuthModal from "../components/Authentication/AuthModal";
import api, { setClientToken } from "../services/api";

const defaultState = {
	userToken: null,
	userData: null,
	isLoading: true,
	isSignout: false,
	authModalOpen: false,
};

export const AuthContext = createContext(defaultState);

export const AuthProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(
		(prevState, action) => {
			switch (action.type) {
				case "RESTORE_TOKEN":
					return {
						...prevState,
						userToken: action.token,
						isLoading: false,
					};
				case "SIGN_IN":
					return {
						...prevState,
						isSignout: false,
						userToken: action.data.jwt,
						userData: action.data.user,
					};
				case "SIGN_OUT":
					return {
						...prevState,
						isSignout: true,
						userToken: null,
						userData: null,
					};
				case "RESTORE_USERDATA":
					return {
						...prevState,
						userData: action.data,
						isLoading: false,
					};
				case "SET_AUTH_MODAL":
					return {
						...prevState,
						authModalOpen: action.isOpen,
					};
				default:
					return;
			}
		},
		{
			isLoading: true,
			isSignout: false,
			userToken: null,
			userData: null,
			authModalOpen: false,
		}
	);

	const getUserDetails = async (token) => {
		api
			.get("users/me", { headers: { Authorization: `Bearer ${token}` } })
			.then((res) => {
				if (res.status === 200) {
					dispatch({ type: "RESTORE_USERDATA", data: res.data });
				}
			})
			.catch((e) => {
				console.error(e.response.data);
			});
	};

	useEffect(() => {
		// Fetch the token from storage then navigate to our appropriate place
		const bootstrapAsync = async () => {
			let userToken;

			// try {
			userToken = await localStorage.getItem("userToken");
			if (userToken) {
				getUserDetails(userToken);
			}

			setClientToken(userToken);
			// } catch (e) {
			// 	// Restoring token failed
			// 	console.error(e)
			// }

			// After restoring token, we may need to validate it in production apps

			// This will switch to the App screen or Auth screen and this loading
			// screen will be unmounted and thrown away.
			dispatch({ type: "RESTORE_TOKEN", token: userToken });
		};

		bootstrapAsync();
	}, []);

	useEffect(() => {
		if (state.userToken && state.authModalOpen) {
			authContext.authModal(false);
		}
	}, [state.userToken]);

	const authContext = React.useMemo(
		() => ({
			signIn: async (data) => {
				await localStorage.setItem("userToken", data.jwt);
				setClientToken(data.jwt);
				dispatch({ type: "SIGN_IN", data: data });
			},
			signOut: async () => {
				await localStorage.removeItem("userToken");
				setClientToken(null);
				dispatch({ type: "SIGN_OUT" });
			},
			signUp: async (token) => {
				await localStorage.setItem("userToken", token);
				dispatch({ type: "SIGN_IN", token });
			},
			setUserData: async (data) => {
				dispatch({ type: "RESTORE_USERDATA", data });
			},
			authModal: async (isOpen) => {
				dispatch({ type: "SET_AUTH_MODAL", isOpen });
			},
		}),
		[]
	);

	return (
		<AuthContext.Provider
			value={{
				...authContext,
				token: state.userToken,
				userData: state.userData,
				authModalOpen: state.authModalOpen,
			}}
		>
			{children}
			<AuthModal
				open={state.authModalOpen}
				onClose={() => authContext.authModal(false)}
			/>
		</AuthContext.Provider>
	);
};

export function useAuth() {
	return React.useContext(AuthContext);
}
