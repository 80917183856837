export const Colors = {
	primary: '#FFC300',
	// primary10: Colors.rgba('#FFC300', 0.10),
	// primary20: Colors.rgba('#FFC300', 0.20),
	// primary30: Colors.rgba('#FFC300', 0.30),
	// primary40: Colors.rgba('#FFC300', 0.40),
	// primary50: Colors.rgba('#FFC300', 0.50),
	// primary60: Colors.rgba('#FFC300', 0.60),
	// primary70: Colors.rgba('#FFC300', 0.70),
	// primary80: Colors.rgba('#FFC300', 0.80),
	// primary90: Colors.rgba('#FFC300', 0.90),

	white10: 'rgba(255,255,255,0.1)',
	white20: 'rgba(255,255,255,0.2)',
	white30: 'rgba(255,255,255,0.3)',
	white40: 'rgba(255,255,255,0.4)',
	white50: 'rgba(255,255,255,0.5)',
	white60: 'rgba(255,255,255,0.6)',
	white70: 'rgba(255,255,255,0.7)',
	white80: 'rgba(255,255,255,0.8)',

	grey10: '#111111',
	grey20: '#222222',
	grey30: '#333333',
	grey40: '#444444',
	grey50: '#555555',
	grey60: '#666666',
	grey70: '#777777',
	grey80: '#888888',
	grey90: '#999999',
	grey: '#aaaaaa',
}

export default {
	Colors
}