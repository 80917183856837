import React, { useEffect, useRef, Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react'
import Button from "./Button";
import { XIcon } from '@heroicons/react/outline'


export default function Modal({onClose, open, children, okLabel, onOk, cancelLabel, onCancel, footer = true, headerTitle, headerSubtitle, header, bodyPadding}) {
	const close = () => {
		if(onClose) {
			onClose();
		}
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
					</Transition.Child>
		
					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
					&#8203;
					</span>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom text-black bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-md sm:w-full ">
							{header && <CardHeader title={headerTitle} subtitle={headerSubtitle} onClose={onClose} />}
							<div style={{padding: bodyPadding ? 30 : 0}}>{children}</div>
							{footer &&
								<div className="bg-white border-t-[1px]  border-t-solid border-t-gray-800  px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
									<Button 
										label={okLabel || "Submit"}
										onClick={onOk} 
										size="large" 
										color="primary" 
									/>
									{onCancel &&
										<Button 
											label={cancelLabel || "Cancel"}
											onClick={onCancel} 
											size="large" 
											color="secondary" 
										/>
									}
								</div>
							}
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}


const CardHeader = ({title, subtitle, onClose}) => {
	return (
		<div className="px-6 py-6 border-b-[1px] border-gray-50">
			<div>
				{title && <div className="text-lg font-semibold ">{title}</div>}
				{subtitle && <div className="text-sm text-gray-300">{subtitle}</div>}
			</div>
			<div className="hidden sm:block absolute top-0 right-0 pt-6 pr-6">
                <button
                  type="button"
                  className="bg-white rounded-xl text-gray-100 hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-primary-500"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
		</div>
	)
}