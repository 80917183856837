import React from "react";

export default function PlayIcon(props) {
	return (
		<svg
			width="2em"
			height="2em"
			viewBox="0 0 24 24"
			fill="none"
			aria-hidden="true"
			className="mc-icon"
			{...props}
		>
			<path
			d="M8.653 6.117A.75.75 0 0 0 7.5 6.75v10.5a.75.75 0 0 0 1.153.633l8.25-5.25a.75.75 0 0 0 0-1.266l-8.25-5.25z"
			fill="currentColor"
			/>
		</svg>
	)
}