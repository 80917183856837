import React from 'react';
import { PlusIcon } from '@heroicons/react/solid'
import PlayIcon from './PlayIcon';
import Spinner from './Spinner';

export default function Button({label, onClick, size = "large", endIcon, startIcon, className, color = "primary", style, fullWidth, uppercase = true, type, disabled, loading, link}) {

  const classes = {
    xsmall: "px-2.5 py-1.5 text-xs",
    small: "px-3 py-2 text-sm leading-4",
    medium: "px-4 py-2 text-sm",
    large: "px-8 py-2 text-[12px]",
    xlarge: "px-8 py-3 text-[12px]"
  }

  const colorClasses = {
    primary: "text-black bg-primary hover:bg-primary focus:ring-primary",
    secondary: "text-white bg-gray-800 hover:bg-gray-800 focus:ring-gray-800",
    white: "text-black bg-white hover:bg-white focus:ring-transparent",

  }

  const renderIcon = (name, location) => {
    let marginClass = location == "start" ? "mr-2" : "ml-2";
    let sizeClass = size == "xsmall" ? "h-4 w-4" : "h-6 w-6";
    let classes = `${marginClass} ${sizeClass}`;
    switch(name) {
      case "PlayIcon":
        return <PlayIcon className={classes} aria-hidden="true" />
      case "PlusIcon":
        return <PlusIcon className={classes} aria-hidden="true" />
    }
  }

  const buttonClasses = `inline-flex items-center border border-transparent rounded-[0.7rem] focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-50 disabled:text-gray-200 h-[48px] font-[600] tracking-wide ${classes[size]} ${className} ${colorClasses[color]} ${fullWidth && "w-full text-center justify-center"} ${uppercase && "uppercase"}`;
  

  return link ? (
    <a
      type={type || "button"}
      className={buttonClasses}
      href={link}
      style={style}
    >
      {loading ? <Spinner /> : (
        <>
          {startIcon && renderIcon(startIcon, "start")}
          {label}
          {endIcon && renderIcon(endIcon, "end")}
        </>
      )}
      
    </a>
  ) : (
    <button
      type={type || "button"}
      className={buttonClasses}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {loading ? <Spinner /> : (
        <>
          {startIcon && renderIcon(startIcon, "start")}
          {label}
          {endIcon && renderIcon(endIcon, "end")}
        </>
      )}
      
    </button>
  )
}

