import React, { useEffect, useRef, Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react'
// import Subscribe from "../Subscription/Subscribe";
import Authentication from ".";
import { useAuth } from "../../context/AuthContext";
import Subscribe from "../subscription/Subscribe";

export default function AuthModal(props) {
	const {open, onClose} = props;
	const {token, userData, authModal} = useAuth();

	const close = () => {
		if(onClose) {
			onClose();
		}
		authModal(false);
	}

	return (
		<Transition.Root show={!!open} as={Fragment}>
			<Dialog as="div" className="fixed z-50  inset-0 overflow-y-auto" onClose={onClose}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
					</Transition.Child>
		
					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
					&#8203;
					</span>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-md sm:w-full ">
							<Authentication mode={open} />
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

