import React from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { classNames } from '../../helpers/common';

export default function Notice({type = "success", message, className}) {
	let iconColor = type === 'success' ? 'text-green-400' : 'text-red-400';
	let bgColor = type === 'success' ? 'bg-green-50' : 'bg-red-50';
	let textColor = type === 'success' ? 'text-green-800' : 'text-red-800';
	let Icon = type === 'success' ? CheckCircleIcon : XCircleIcon;
	return (
		<div className={classNames(`rounded-xl ${bgColor} p-4`, className)}>
			<div className="flex">
				<div className="flex-shrink-0">
					<Icon className={`h-5 w-5 ${iconColor}`} aria-hidden="true" />
				</div>
				<div className="ml-3">
					<p className={`text-sm font-medium ${textColor}`}>{message}</p>
				</div>
			</div>
		</div>
	)
}